import type { FC } from 'react'
import './index.scss'
import seachIcon from '@assets/images/home/search.png'
import seachIconLight from '@assets/images/home/icon_search_light.png'
import classNames from 'classnames'

export enum EnumSearchTheme {
  DARK = 'dark',
  LIGHT = 'light',
}
interface Props {
  theme?: EnumSearchTheme
  text: string
  boldText?: string
  style?: React.CSSProperties
  className?: string
}

const Search: FC<Props> = (props) => {
  const { theme = EnumSearchTheme.DARK, text, boldText, className, style } = props

  const cls = classNames('c-search', className, {
    'c-search--dark': theme === EnumSearchTheme.DARK,
    'c-search--light': theme === EnumSearchTheme.LIGHT,
  })

  return (
    <div className={cls} style={style}>
      <img src={theme === EnumSearchTheme.DARK ? seachIcon : seachIconLight} alt="" />
      <span className="c-search__text">{text}</span>
      <span className="c-search__boldText">{boldText}</span>
    </div>
  )
}

export default Search
