import type { FC } from 'react'
import './index.scss'
import Search from '../../components/Search'
import banner from '../../assets/images/brandOnboarding/banner.png'
import { Layout } from '../../components/Layout'
interface Props {}

const BrandOnboardingPage: FC<Props> = (props) => {
  const stepList = [
    {
      step: 'STEP1 ‣ ',
      title: '邮件提交入驻资料',
      desc: (
        <section style={{ fontSize: '14px', fontWeight: 300, lineHeight: '200%' }}>
          <p>提交以下入驻资料至邮箱</p>
          <p style={{ color: '#db322e' }}>procurement@cdfsunrise.com</p>
        </section>
      ),
      content: [' 营业执照', ' 品牌介绍', ' 品牌商标证书/授权证明', ' 联系方式'],
    },
    {
      step: 'STEP2 ‣ ',
      title: '品牌等待审核',
      desc: null,

      content: [' 品牌资质评估', ' 资质审核'],
    },
    {
      step: 'STEP3 ‣ ',
      title: '采购合同签订',
      desc: null,
      content: [' 确认合同内容并完成签订', ' 签署协议并锁定保证金'],
    },
    {
      step: 'COMPLETE',
      title: '品牌入驻成功',
      desc: null,
      content: [' 品牌正式入驻'],
    },
  ]
  return (
    <Layout>
      <section className="page-container brand-onboarding-page">
        <section className="banner">
          <img src={banner} />
        </section>
        <section className="brand-step-wrapper">
          <section className="title-wrapper">
            <h1 className="title">品牌入驻</h1>
            <h4 className="sub-title">BRAND ONBOARDING</h4>
          </section>

          <section className="step-list">
            {stepList?.length &&
              stepList.map((step) => {
                return (
                  <section className="step-item">
                    <header className="step-title">{step.step}</header>
                    <article className="step-content">
                      <h3>{step.title}</h3>
                      {step.desc && <p className="desc">{step.desc}</p>}
                      <ul className="list">
                        {step.content?.length &&
                          step.content.map((content) => (
                            <li>
                              <span style={{ color: '#db322e' }}>‣</span>
                              {content}
                            </li>
                          ))}
                      </ul>
                    </article>
                  </section>
                )
              })}
          </section>

          <section className="search-wrap text-center">
            <h2>即刻开启 非凡购物之旅</h2>
            <Search style={{ margin: '0 auto' }} text="应用商店/微信小程序/支付宝小程序搜索" boldText="中免日上" />
          </section>
        </section>
      </section>
    </Layout>
  )
}

export default BrandOnboardingPage
