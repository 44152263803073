import type { FC } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import '../../assets/scss/common.scss'
import './index.scss'

interface Props {
  children: JSX.Element
}

export const Layout: FC<Props> = ({ children }) => {
  return (
    <div className="official-home-frame">
      <Header />
      {children}
      <Footer />
    </div>
  )
}
