import imgQrCode1 from '@assets/images/common/footer/qrCode1.jpg'
import imgQrCode2 from '@assets/images/common/footer/qrCode2.jpg'
import imgQrCode3 from '@assets/images/common/footer/qrCode3.jpg'
import imgQrCode4 from '@assets/images/common/footer/qrCode4.jpg'
import imgQrCode5 from '@assets/images/common/footer/qrCode5.jpg'

import type { FC } from 'react'
import Description from '../Description'
import './index.scss'

const copyright = '沪ICP备2021015255号-1 Copyright © 2021-2023 中免日上互联科技有限公司'
const mediaInfo = [
  { img: imgQrCode1, desc: '微信公众号' },
  { img: imgQrCode2, desc: '微信视频号' },
  { img: imgQrCode3, desc: '微博' },
  { img: imgQrCode4, desc: '小红书' },
  { img: imgQrCode5, desc: '抖音' },
]

const Footer: FC = () => {
  return (
    <div className="footer-page">
      <div className="page-container">
        <section className="info-wrapper flexRow">
          <Description
            title={'客服热线'}
            titleStyle={{ fontSize: '20px' }}
            style={{ width: '280px' }}
            desc={
              <>
                <p>在线客服：App/小程序</p>
                <p>工作时间：9:00-23:00</p>
                <p>电话客服：4006130133</p>
                <p>工作时间：10:00-22:00</p>
              </>
            }
          />
          <Description
            title={'品牌入驻'}
            titleStyle={{ fontSize: '20px' }}
            desc={
              <>
                <p>procurement@cdfsunrise.com</p>
                <Description
                  title={'证照信息'}
                  titleStyle={{ fontSize: '20px', marginTop: '40px' }}
                  desc={
                    <>
                      <p>
                        <a href="https://lefox-app-cdn.cdfsunrise.com/html/statement/certificate.html" target="_blank" rel="noopener noreferrer">
                          点击查看
                        </a>
                      </p>
                    </>
                  }
                  style={{ width: '280px' }}
                />
              </>
            }
            style={{ width: '280px' }}
          />
          <Description
            title={'社交媒体'}
            titleStyle={{ fontSize: '20px' }}
            style={{ width: '578px' }}
            desc={
              <div className="media-box">
                {mediaInfo.map((item) => {
                  return (
                    <div className="media-item" key={item.desc}>
                      <img src={item.img} alt={item.desc + '二维码'} />
                      <p className="media-item-desc">{item.desc}</p>
                    </div>
                  )
                })}
              </div>
            }
          />
        </section>
        <div className="copyright">{copyright}</div>
      </div>
    </div>
  )
}

export default Footer
