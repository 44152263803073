import React, { Fragment, Suspense } from 'react'
import { Outlet, Route, Routes } from 'react-router'

export interface RouteRow {
  key?: string
  isIndex?: boolean
  path: string // 页面网址路由
  route?: string // 页面路径
  lazy?: boolean
  name?: string
  component: React.ComponentType<any>
  children?: RouteRow[]
}

interface P {
  routes: RouteRow[]
}

const App = (props: P) => {
  const { routes } = props
  return (
    <Routes>
      {routes.map((item, index) => {
        const Comp = item.component
        return <Route caseSensitive path={item.path} key={item.path} element={<Comp />}></Route>
      })}

      <Route
        path="*"
        element={
          <div style={{ padding: '1rem', fontSize: 20, textAlign: 'center' }}>
            <p>{'null'}</p>
          </div>
        }
      />
    </Routes>
  )
}

export default App
