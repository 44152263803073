import classNames from 'classnames'
import type { FC } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/images/common/icon_logo.png'
import { routeMapping } from '../../routes'
import './index.scss'
interface Props { }

const HomeHeader: FC<Props> = (props) => {
  return (
    <div className="header-page">
      <Link to="/">
        <img src={logo} className="logo" />
      </Link>
      <div className="nav-wrapper">
        {routeMapping.map((item) => {
          return (
            <NavLink
              to={item.path}
              key={item.name}
              className={({ isActive }) => {
                return classNames('nav-item', {
                  'nav-item-active': isActive,
                })
              }}
              onClick={() => {
                window.scrollTo(0, 0)
              }}
            >
              {item.name}
            </NavLink>
          )
        })}
      </div>
    </div>
  )
}

export default HomeHeader
