import aboutUs1 from '@assets/images/home/us_1.jpg'
import aboutUsIcon1 from '@assets/images/home/us_1_icon.png'
import aboutUs2 from '@assets/images/home/us_2.jpg'
import aboutUsIcon2 from '@assets/images/home/us_2_icon.png'
import aboutUs3 from '@assets/images/home/us_3.jpg'
import aboutUsIcon3 from '@assets/images/home/us_3_icon.png'

import ourServiceApp1 from '@assets/images/home/app_1.jpg'
import ourServiceApp2 from '@assets/images/home/app_2.jpg'
import ourServiceApp3 from '@assets/images/home/app_3.jpg'
import ourServiceApp4 from '@assets/images/home/app_4.jpg'
import ourServiceAppAR1 from '@assets/images/home/app_ar_1.jpg'
import ourServiceAppAR2 from '@assets/images/home/app_ar_2.jpg'
import ourServiceAppAR3 from '@assets/images/home/app_ar_3.png'
import ourServiceAppSighLeft from '@assets/images/home/app_sign_left.png'
import ourServiceAppSignRight from '@assets/images/home/app_sign_right.png'

/** 关于我们  */
export const aboutUsBlocks = [
  {
    img: aboutUs1,
    title: '中国旅游集团',
    desc: '中免集团母公司中国旅游集团由国务院国资委管理的中国最大旅游央企，在旅游产业全方位布局，服务网络遍布内地、港澳和海外近30个国家和地区。',
    icon: aboutUsIcon1,
  },
  {
    img: aboutUs2,
    title: '中免集团',
    desc: '中免集团成立于1984年，是经国务院授权在全国范围内开展免税业务的国有专营公司，在海内外经营200+家免税店，每年为近2亿人次的国内外旅客提供服务，是全球最大的免税运营商。',
    icon: aboutUsIcon2,
  },
  {
    img: aboutUs3,
    title: '日上免税行',
    desc: '日上免税行成立于1999年，经国家批准在上海虹桥和浦东机场、北京首都机场和大兴机场经营免税业务，在2018年成为中免集团的控股子公司。',
    icon: aboutUsIcon3,
  },
]

/** 我们的服务 左侧文字 */
export const ourServicesText = [
  {
    title: '正品保障',
    desc: '平台依托中免集团免税品直采供应链，集合中免集团旗下门店大牌正品，商品受海关全程严格监管，为用户提供足不出户、买遍全球的便捷优质服务。',
  },
  {
    title: '创新体验',
    desc: '平台以国际奢品风格为导向，注重产品品质和品牌文化，向消费者传递高格调、年轻化的品质追求。技术赋能，不断创新AR肌肤检测、智慧标签、AR试妆等功能，开发人货场精准匹配算法，全链条提升为消费者服务品质。',
  },
]

/** 我们的服务 第一行图片*/
export const ourServicesImagesRow1 = [{ img: ourServiceApp1 }, { img: ourServiceApp2 }, { img: ourServiceApp3 }, { img: ourServiceApp4 }]
/** 我们的服务 第二行图片 和 双引号图片 */
export const ourServicesImagesRow2 = [
  { img: ourServiceAppAR1 },
  { img: ourServiceAppAR2 },
  { img: ourServiceAppAR3 },
  { img: ourServiceAppSignRight, className: 'icon_sign icon_sign_right' },
  { img: ourServiceAppSighLeft, className: 'icon_sign icon_sign_left' },
]
