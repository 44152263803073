import type { FC } from 'react'
import './index.scss'
import Search, { EnumSearchTheme } from '../../components/Search'
import Title from '../../components/Title'

import p1 from '@assets/images/download/p1.png'
import p2 from '@assets/images/download/p2.png'
import p3 from '@assets/images/download/p3.png'
import qrCode_1 from '@assets/images/download/qrCode_1.jpg'
import qrCode_2 from '@assets/images/download/qrCode_2.jpg'
import qrCode_3 from '@assets/images/download/qrCode_3.jpg'
import imgHero from '@assets/images/download/bg_banner.png'
import Description from '../../components/Description'
import { NavLink } from 'react-router-dom'
import { Layout } from '../../components/Layout'

/*
 multi-columns布局

 https://blog.csdn.net/Artful_Dodger/article/details/79761760
*/
const imgList = [
  {
    type: 'text',
    img: p1,
    title: '品牌授权 正品保障',
    desc: (
      <>
        坚守大牌正品、倡导品质消费
        <br />
        所有在售商品接受海关全程严格监管
      </>
    ),
  },
  {
    type: 'img',
    img: p1,
    title: '品牌授权 正品保障',
    desc: (
      <>
        坚守大牌正品、倡导品质消费
        <br />
        所有在售商品接受海关全程严格监管
      </>
    ),
  },

  {
    type: 'text',
    img: p2,
    title: '足不出户 买遍全球',
    desc: (
      <>
        集合中免集团旗下各大旅游零售实体商品，涵盖
        <br />
        护肤、彩妆、香水、洋酒、时尚精品等众多品类
      </>
    ),
  },
  {
    type: 'img',
    img: p2,
    title: '足不出户 买遍全球',
    desc: (
      <>
        集合中免集团旗下各大旅游零售实体商品，涵盖
        <br />
        护肤、彩妆、香水、洋酒、时尚精品等众多品类
      </>
    ),
  },
  {
    type: 'text',
    img: p3,
    title: '大牌上新 轻松体验',
    desc: (
      <>
        持续不断更新商品上新品种与系列款式
        <br />
        永远为每一位用户准备购物的惊喜
      </>
    ),
  },
  {
    type: 'img',
    img: p3,
    title: '大牌上新 轻松体验',
    desc: (
      <>
        持续不断更新商品上新品种与系列款式
        <br />
        永远为每一位用户准备购物的惊喜
      </>
    ),
  },
]

const mediaInfo = [
  { img: qrCode_1, desc: 'App' },
  { img: qrCode_2, desc: '微信小程序' },
  { img: qrCode_3, desc: '支付宝小程序' },
]

interface Props { }

const DownloadPage: FC<Props> = (props) => {
  return (
    <Layout>
      <div className="page-container download-page">
        {/* <section className="section banner-section">
          <img src={imgHero} style={{ backgroundColor: '#101010' }} />
          <div className="custom center">
            <Title className="component-title--thin" title="中免日上 品质生活之旅" />
            <Search className="mt30" theme={EnumSearchTheme.LIGHT} text="应用商店/微信小程序/支付宝小程序搜索" boldText="中免日上" />
          </div>
        </section> */}

        <section className="section product-section">
          <Title className="component-title-download" title="产品介绍" subTitle="Product introduction" />
          <div className="gallery">
            {imgList.map((item) => {
              const key = item.type + item.title
              if (item.type === 'img') {
                return (
                  <div className="gallery-item gallery-item-img" key={key}>
                    <img src={item.img} alt={`${item.title} ${item.desc}`} />
                  </div>
                )
              }
              return (
                <div className="gallery-item gallery-item-text" key={item.img}>
                  <Description key={key} title={item.title} desc={item.desc} />
                </div>
              )
            })}
          </div>
        </section>

        <section className="section download-section">
          <Title title="下载与体验" subTitle="Download & Experience" />
          <div className="custom">
            <div className="media-box">
              {mediaInfo.map((item) => {
                return (
                  <div className="media-item" key={item.desc}>
                    <img src={item.img} alt={item.desc + '二维码'} />
                    <p>{item.desc}</p>
                  </div>
                )
              })}
            </div>
            <div className="link-wrapper">
              <NavLink className="link" to={'/terms'}>
                查看平台购买须知
              </NavLink>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default DownloadPage
