import { Fragment, useEffect, useMemo } from 'react'
import cn from 'classnames'
import s from './index.module.scss'

import p1 from '../../assets/images/p1.png'
import p2 from '../../assets/images/p2.png'
import p3 from '../../assets/images/p3.png'
import p4 from '../../assets/images/p4.png'
import p5 from '../../assets/images/p5.png'
import p6 from '../../assets/images/p6.png'
import p7 from '../../assets/images/p7.png'

const Home = () => {
  // 页面打开之际
  useEffect(() => {
    //
  }, [])

  return useMemo(() => {
    return (
      <Fragment>
        <div className={s.main}>
          <img src={p1} className={cn(s.pic, s.pic1)} />
          <img src={p2} className={cn(s.pic, s.pic2)} />
          <img src={p3} className={cn(s.pic, s.pic3)} />
          <img src={p4} className={cn(s.pic, s.pic4)} />
          <img src={p5} className={cn(s.pic, s.pic5)} />
          <img src={p6} className={cn(s.pic, s.pic6)} />
          <a href="https://beian.miit.gov.cn/">
            <img src={p7} className={cn(s.pic, s.pic7)} />
          </a>
        </div>
      </Fragment>
    )
  }, [])
}

export default Home
