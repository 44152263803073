import React, { useState, useEffect, useCallback } from 'react';
import arrowLeft from '@assets/images/cooperation/left_arrow.png'
import arrowRight from '@assets/images/cooperation/right_arrow.png'
import './index.scss'


type Slide = {
  id: number;
  imageUrl: string;
  caption?: string;
};

type Props = {
  slides: Slide[];
  interval?: number;
  currentIndex?: number;
  handleSlideChange?: (index: number) => void;
};

const Slideshow: React.FC<Props> = ({ slides, currentIndex = 0, handleSlideChange }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToSlide = useCallback((index: number) => {
    setCurrentSlide(index);
    handleSlideChange(index)
  }, [handleSlideChange]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  //   }, interval);
  //   return () => clearInterval(timer);
  // }, [slides.length, interval]);

  useEffect(() => {
    setCurrentSlide(currentIndex);
  }, [currentIndex]);

  return (
    <div className="slideshow">
      <button className="control prev" onClick={() => goToSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1)}>
        <img src={arrowLeft} alt="arrow-left" />
      </button>

      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`slide ${index === currentSlide ? 'slide-active' : ''}`}
        // style={{ background: `url(${slide.imageUrl}) 100% 100%` }}
        >
          <img src={slide.imageUrl} alt="" />
        </div>
      ))}

      <button className="control next" onClick={() => goToSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1)}>
        <img src={arrowRight} alt="arrow-right" />
      </button>
    </div>
  );
};

export default Slideshow;
