/**
 @page 标题组件
 @description 用于页面标题
**/
import type { FC } from 'react'
import './index.scss'
import classNames from 'classnames'

interface Props {
  /** 标题 */
  title: string
  /** 副标题 */
  subTitle?: string
  /** class 名称 */
  className?: string
  /** 容器 style */
  style?: React.CSSProperties
}

const Title: FC<Props> = (props) => {
  const { title, subTitle, className, style } = props

  const cls = classNames('component-container', 'component-title', className)
  return (
    <div className={cls} style={style}>
      <div className="content-box">
        <div className="title">{title}</div>
        <div className="subTitle">{subTitle}</div>
      </div>
    </div>
  )
}

export default Title
