import classNames from 'classnames'
import type { FC, ReactElement } from 'react'
import './index.scss'

interface Props {
  className?: string
  title: string
  desc?: string | ReactElement
  style?: React.CSSProperties
  titleStyle?: React.CSSProperties
  descStyle?: React.CSSProperties
}

/** 标题和描述组合 */
const Description: FC<Props> = (props) => {
  const { className, title, desc, style, titleStyle, descStyle } = props
  const cls = classNames('c-description', className)
  return (
    <div className={cls} style={style}>
      <div className="c-description__title" style={titleStyle}>
        {title}
      </div>
      {desc && (
        <div className="c-description__desc" style={descStyle}>
          {desc}
        </div>
      )}
    </div>
  )
}

export default Description
