import type { FC } from 'react'
import './index.scss'
import Title from '../../components/Title'
import slogan1 from '@assets/images/culture/slogan-1.jpg'
import slogan2 from '@assets/images/culture/slogan-2.jpg'
import slogan3 from '@assets/images/culture/slogan-3.jpg'

import values1 from '@assets/images/culture/values-1.png'
import values2 from '@assets/images/culture/values-2.png'
import values3 from '@assets/images/culture/values-3.png'
import values4 from '@assets/images/culture/values-4.png'

import esg1 from '@assets/images/culture/esg-1.jpg'
import esg2 from '@assets/images/culture/esg-2.jpg'
import punctuation from '@assets/images/culture/punctuation.png'
import { Layout } from '../../components/Layout'

interface Props {}
const sloganList = [
  {
    imageUrl: slogan1,
    title: '让更多的消费者拥有更高品质的生活',
    desc: '中免日上以消费者为中心、以诚信经营为理念，坚持“正品、优价、好物”。为消费者提供更多的全球优质商品、更好的购物体验、更周到的关怀服务，不断满足消费者对美好生活的追求和向往。',
  },
  {
    imageUrl: slogan2,
    title: '品质生活电商的领先平台',
    desc: '中免日上提供的不仅是全球优质商品，更重要的是通过科技赋能和优质服务引领消费趋势和潮流，将消费体验和品牌赋能有机结合，洞察客户需求、优化供应链体系、持续为品牌赋能。倾力打造集线上和线下多模式、完税和跨境多业态的一站式领先平台，成为链接消费者和品牌供应商的桥梁纽带。',
  },
  {
    imageUrl: slogan3,
    title: '品质生活之旅',
    desc: '我们相信，生命本身就是一场旅行，不应仅仅看重目的地，更要保证贯穿行程始终的高品质体验。中免日上作为中国旅游集团旗下的旅游零售电商平台，将始终致力于为广大消费者汇集全球优质商品、不断提升客户服务体验，以满足消费者崇尚真我的特质和追寻品质生活的理念，乐享品质生活美好旅程。',
  },
]
const valuesList = [
  {
    imageUrl: values1,
    title: '客户至上',
    desc: '我们坚持企业发展的价值以客户的满意来考量，深耕品质与服务，着力提升对客户（涵盖消费者和品牌供应商）的服务水平和质量。以高品质的产品、高水准的服务、高价值的赋能，超越客户的满意度，实现共享共赢。',
  },
  {
    imageUrl: values2,
    title: '勇于争先',
    desc: '我们坚持精益求精、追求卓越，为达成目标全力以赴，实现健康可持续发展。',
  },
  {
    imageUrl: values3,
    title: '创新发展',
    desc: '我们通过在产品、体验、服务、经营和管理上的创新和精进，推动和践行“用户价值、品牌价值、平台价值”。引领企业乃至行业的高品质发展，不断打造可持续发展的软实力，提升消费者和品牌方的感知力，让中免日上高品质的品牌形象根植于客户心中。',
  },
  {
    imageUrl: values4,
    title: '和而不同',
    desc: '我们以“和合”思想有效平衡和协调企业内部之间，企业与客户、合作伙伴、同行以及社会之间的利益关系，创造内和外顺、百花齐放的共生环境。',
  },
]
const CulturePage: FC<Props> = (props) => {
  return (
    <Layout>
      <div className="page-container culture-page">
        <Title title="企业文化" subTitle="corporate culture" />
        <div className="slogan-list">
          {sloganList.map((item) => {
            return (
              <div className="slogan-item" key={item.title}>
                <div className="slogan-img">
                  <img src={item.imageUrl} alt="" />
                </div>
                <div className="slogan-title">{item.title}</div>
                <div className="slogan-desc">{item.desc}</div>
              </div>
            )
          })}
        </div>
        <Title title="企业价值观" subTitle="Corporate values" />
        <div className="values-list">
          {valuesList.map((item) => {
            return (
              <div className="values-item" key={item.title}>
                <div className="values-img">
                  <img src={item.imageUrl} alt="" />
                </div>
                <div className="values-title">{item.title}</div>
                <div className="values-desc">{item.desc}</div>
              </div>
            )
          })}
        </div>
        <Title title="企业ESG" subTitle="Enterprise ESG" />
        <div className="esg-content">
          <div className="esg-left">
            <img src={esg1} alt="" />
          </div>
          <div className="esg-punctuation">
            <img src={punctuation} alt="" />
          </div>
          <div className="esg-center">
            <div className="esg-item">
              <div className="esg-center-title">
                <span className="title-specical">a.</span>践行责任营销，引领行业发展
              </div>
              <div className="esg-center-desc">我们建立严格的管理流程，保障营销内容的合规、真实及有效。同时，我们致力于通过正向、积极的营销内容引领消费者，传递美丽、健康及自信的价值观。</div>
            </div>
            <div className="esg-item">
              <div className="esg-center-title">
                <span className="title-specical">b.</span> 重视科研创新，提升用户体验
              </div>
              <div className="esg-center-desc">我们大力投入科技研发，以科技力量提升顾客体验，建立全链条质量控制，形成完整的商品质量和服务保障体系，保证服务质量和客户体验。</div>
            </div>
            <div className="esg-item">
              <div className="esg-center-title">
                <span className="title-specical">c.</span> 维护员工权益，践行关爱之心
              </div>
              <div className="esg-center-desc">
                我们始终坚持“人才是企业发展的核心”,
                致力于建立多元、平等、和谐发展的工作环境。不断完善绩效考核体系，客观评价员工的工作表现，重视员工的职业发展，确保所有员工不因种族、性别、年龄、宗教信仰或残疾等各种因素而受到歧视与差别化对待。
                <div className="esg-center-desc">公司保障员工福利，关爱员工健康，并兴建了网球场/篮球场/室内羽毛球和乒乓球场、购置健身器材，供员工免费使用。</div>
              </div>
            </div>
            <div className="esg-item">
              <div className="esg-center-title">
                <span className="title-specical">d.</span> 践行环保公益，持续回报社会
              </div>
              <div className="esg-center-desc">
                绿色办公：公司使用太阳能为办公场所供电，提供班车为员工通勤服务，推行无纸化办公。
                <div className="esg-center-desc">绿色物流：优先选取低碳、可再生、可回收的环保材料用于快递包装，不断减少非环保包材使用量，积极推进对符合条件的包装物回收再利用，减少废弃物产生量。</div>
              </div>
            </div>
          </div>
          <div className="esg-right">
            <img src={esg2} alt="" />
          </div>
          <div className="esg-end">RESPONSIBILITY</div>
        </div>
      </div>
    </Layout>
  )
}

export default CulturePage
