import Home from '../pages/index'

import HomeNew from '../pages/home/index'
import BrandOnboarding from '../pages/brandOnboarding/index'
import ContactUs from '../pages/contactUs/index'
import Cooperation from '../pages/cooperation/index'
import Culture from '../pages/culture/index'
import Download from '../pages/download/index'
import Terms from '../pages/terms/index'
import type { RouteRow } from './renderRoutes'

export const routeMapping: RouteRow[] = [
  {
    path: '/home',
    component: Home,
  },
  {
    name: '首页',
    path: '/',
    component: HomeNew,
  },
  {
    name: '合作场景',
    path: '/cooperation',
    component: Cooperation,
  },
  {
    name: '企业文化',
    path: '/culture',
    component: Culture,
  },
  {
    name: '下载与体验',
    path: '/download',
    component: Download,
  },
  {
    name: '品牌入驻',
    path: '/brand',
    component: BrandOnboarding,
  },

  {
    name: '联系我们',
    path: '/contact',
    component: ContactUs,
  },
  {
    path: '/terms',
    component: Terms,
  },
]
