import type { FC } from 'react'
import Description from '../../components/Description'
import Search, { EnumSearchTheme } from '../../components/Search'
import SideTitle from '../../components/SideTitle'
import imgCooperation1 from '@assets/images/home/cooperation1.jpg'
import imgCooperation2 from '@assets/images/home/cooperation2.jpg'
import imgHero from '@assets/images/home/bg.png'

import { aboutUsBlocks, ourServicesImagesRow1, ourServicesImagesRow2, ourServicesText } from './constants'
import './index.scss'
import Title from '../../components/Title'
import { Layout } from '../../components/Layout'

interface Props {}

const HomePage: FC<Props> = () => {
  return (
    <Layout>
      <div className="page-container home-page">
        {/* 中免日上品质生活之旅 */}
        <section className="section banner-section">
          <img src={imgHero} />
          <div className="custom center">
            <Title title="中免日上品质生活之旅" style={{ paddingBottom: '30px' }} />
            <p className="section__desc">
              我们相信，生命本身就是一场旅行
              <br />
              不应仅仅看重目的地
              <br />
              更要保证贯穿行程始终的高品质体验
            </p>
            <div className="">
              <Search style={{ marginTop: '52px' }} theme={EnumSearchTheme.DARK} text={'应用商店/微信小程序/支付宝小程序搜索'} boldText={'中免日上'} />
            </div>
          </div>
        </section>

        {/* 关于我们 */}
        <section className="section about-us-section">
          <Title title="关于我们" subTitle="about us" />
          <p className="section__desc">中免日上互联科技有限公司由中国免税品集团与日上免税行携手创立</p>
          <div className="blocks">
            {aboutUsBlocks.map((item) => {
              return (
                <div key={item.title} className="block-item">
                  <img className="block-image" src={item.img} />
                  <p className="block-title">{item.title}</p>
                  <p className="block-desc">{item.desc}</p>
                  <img className="block-logo" src={item.icon} />
                </div>
              )
            })}
          </div>
        </section>

        <section className="section service-section">
          <Title title="我们的服务" subTitle="Our Services" />
          <p className="section__desc">公司拥有强大的科技专业力量， 自研开发和运营“中免日上”专业电商平台</p>
          <div className="custom">
            <div className="left">
              {ourServicesText.map((item, i) => {
                return <Description className={`description${i}`} key={item.title} title={item.title} desc={item.desc} descStyle={{ fontWeight: 300 }} style={{ width: '240px' }} />
              })}
              <span className="corner-label">experience</span>
            </div>
            <div className="right">
              <div className="img-row img-row1">
                {ourServicesImagesRow1.map((item, i) => {
                  return <img className={`img img${i}`} src={item.img} key={item.img} />
                })}
              </div>
              <div className="img-row img-row2">
                {ourServicesImagesRow2.map((item, i) => {
                  return <img className={item.className ? item.className : `img img${i + 10}`} src={item.img} key={item.img} />
                })}
              </div>
            </div>
          </div>
        </section>

        <section className="section partner-section">
          <Title title="我们的合作伙伴" subTitle="Our partners" />
          <p className="section__desc">
            合作品牌<span className="highlight">300+</span>，包括香水化妆品、精品配饰、酒水、食品等
            <br />
            与各行业的标杆企业一起共创用户生态，深度多样化合作，服务用户
          </p>
          <div className="custom">
            <SideTitle title="合作品牌" subTitle="Co-branding" />
            <img className="brand-wall" src={imgCooperation1} />
            <SideTitle title="异业合作伙伴" subTitle="Cross-industry partners" />
            <img className="brand-wall " src={imgCooperation2} />
          </div>
        </section>

        <section className="section end-section">
          <Title className={'component-title--thin'} title="即刻开启 非凡购物之旅" />
          <Search className="mt30 " theme={EnumSearchTheme.DARK} text={'应用商店/微信小程序/支付宝小程序搜索'} boldText={'中免日上'} />
        </section>
      </div>
    </Layout>
  )
}

export default HomePage
