import type { FC } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import './index.scss'
import Title from '../../components/Title'
import Slide from '../../components/Slide'
import mode1 from '@assets/images/cooperation/mode-1.png'
import mode2 from '@assets/images/cooperation/mode-2.png'
import mode3 from '@assets/images/cooperation/mode-3.png'
import cooperation1 from '@assets/images/cooperation/cooperation-1.png'
import cooperation2 from '@assets/images/cooperation/cooperation-2.png'
import cooperation3 from '@assets/images/cooperation/cooperation-3.png'
import cooperation4 from '@assets/images/cooperation/cooperation-4.png'
import composite1 from '@assets/images/cooperation/composite-1.png'
import composite2 from '@assets/images/cooperation/composite-2.png'
import composite3 from '@assets/images/cooperation/composite-3.png'
import composite4 from '@assets/images/cooperation/composite-4.png'
import composite5 from '@assets/images/cooperation/composite-5.png'
import composite6 from '@assets/images/cooperation/composite-6.png'
import composite7 from '@assets/images/cooperation/composite-7.png'
import composite8 from '@assets/images/cooperation/composite-8.jpeg'
import test1 from '@assets/images/cooperation/1.jpg'
import test2 from '@assets/images/cooperation/3.jpg'
import test3 from '@assets/images/cooperation/2.jpg'
import test4 from '@assets/images/cooperation/4.jpg'
import test11 from '@assets/images/cooperation/1-1.jpg'
import test22 from '@assets/images/cooperation/2-2.jpg'
import test33 from '@assets/images/cooperation/3-3.jpg'
import test44 from '@assets/images/cooperation/4-4.jpg'
import { Layout } from '../../components/Layout'

interface Props { }

const slides = [
  {
    id: 1,
    imageUrl: test1,
    imageUrlSmall: test11,
    caption: 'Slide 1',
  },
  {
    id: 2,
    imageUrl: test2,
    imageUrlSmall: test22,
    caption: 'Slide 2',
  },
  {
    id: 3,
    imageUrl: test3,
    imageUrlSmall: test33,
    caption: 'Slide 3',
  },
  {
    id: 4,
    imageUrl: test4,
    imageUrlSmall: test44,
    caption: 'Slide 4',
  },
]

const multiFormat = [
  {
    imageUrl: mode3,
    desc: '一般贸易',
  },
  {
    imageUrl: mode2,
    desc: '免税预定',
  },
  {
    imageUrl: mode1,
    desc: '跨境电商',
  },
]
const compositeData = [
  {
    imageUrl: composite1,
    desc: '综合展演',
  },
  {
    imageUrl: composite2,
    desc: '保税展销',
  },
  {
    imageUrl: composite3,
    desc: '智能仓储',
  },
  {
    imageUrl: composite4,
    desc: '办公餐饮',
  },
]

const asynchronousCooperation = [
  {
    imageUrl: cooperation1,
    title: '客户资源互享',
    desc: ['银行公众号发放优惠券', '支付宝小程序入驻'],
  },
  {
    imageUrl: cooperation2,
    title: '会员权益共享',
    desc: ['积分兑换券 & 积分商城', '高端持卡人领取权益优惠'],
  },
  {
    imageUrl: cooperation3,
    title: '跨界联合营销活动',
    desc: ['银行支付补贴', '机场国旅活动宣传'],
  },
  {
    imageUrl: cooperation4,
    title: '高端会员制员工福利',
    desc: ['企业员工会员补贴'],
  },
]

const CooperationPage: FC<Props> = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  // const [isHover, setIsHover] = useState(false)
  // const [interval] = useState(10000)

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1))
  //   }, interval)
  //   return () => clearInterval(timer)
  // }, [interval])
  return useMemo(() => {
    return (
      <Layout>
        <div className="page-container cooperation-page">
          <Title title="品牌合作" subTitle="Brand cooperation" />
          <div className="cooperation-desc">
            <span>平台聚集了国际知名的中高端品牌名优品牌甄选入驻彰显品牌形象和品位</span>
            <span>为品牌提供一站式全案营销推广，传播品牌形象和文化</span>
          </div>
          <div className="cooperation-slide">
            <Slide
              slides={slides}
              currentIndex={currentSlide}
              handleSlideChange={(val) => {
                setCurrentSlide(val)
              }}
            />
            <div className="cooperation-list">
              {slides.map((item, index) => {
                return (
                  <div
                    className={`cooperation-item ${index === currentSlide ? 'active' : ''}`}
                    key={item.id}
                    // onClick={() => {
                    //   setCurrentSlide(index)
                    // }}
                    onMouseEnter={() => {
                      setCurrentSlide(index)
                    }}
                  >
                    <img src={item.imageUrlSmall} alt={item.caption} />
                  </div>
                )
              })}
            </div>
          </div>
          <Title title="多业态模式" subTitle="Multi-format model" />
          <div className="cooperation-desc">
            <span>
              中免日上依托中免集团和日上免税行旅游产业链资源支持，大力发展“<span style={{ fontWeight: 600 }}>线上</span>
              <span style={{ color: '#DB322E', fontWeight: 400 }}>+</span>
              <span style={{ fontWeight: 600 }}>线下</span>”全渠道模式和多业态一站式平台
            </span>
            <span>拥有强大的自研科技能力，为用户提升消费体验，为品牌赋能，与合作伙伴开展多模式合作</span>
          </div>
          <div className="cooperation-multi">
            {multiFormat.map((item) => {
              return (
                <div key={item.desc} className="cooperation-multi-item">
                  <img src={item.imageUrl} alt={item.desc} />
                  <span>{item.desc}</span>
                </div>
              )
            })}
          </div>
          <Title title="异业合作" subTitle="Cross-industry cooperation" />
          <div className="cross-cooperation-list">
            {asynchronousCooperation.map((item) => {
              return (
                <div key={item.title} className="cross-cooperation-item">
                  <img src={item.imageUrl} alt={item.title} />
                  <div className="cross-cooperation-item-title">
                    <span>{item.title}</span>
                  </div>
                  <div className="cross-cooperation-item-desc">
                    {item.desc.map((desc) => {
                      return <span key={desc}>{desc}</span>
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <Title title="中免日上综合体" subTitle="CDF SUNRISE COMPLEX" />
          <div className="cooperation-desc">
            <span>
              中免日上是中国国际进口博览会上海交易团指定的<span style={{ fontWeight: 600 }}>6天</span>
              <span style={{ color: '#DB322E', fontWeight: 400 }}>+</span>
              <span style={{ fontWeight: 600 }}>365天</span>”交易服务平台
            </span>
            <span>线上平台与线下综合体结合，为进博会参展商、采购商、服务商提供线上线下结合、多渠道、多模式一站式服务</span>
          </div>
          <div className="composite1-multi">
            {compositeData.map((item) => {
              return (
                <div key={item.desc} className="composite1-multi-item">
                  <img src={item.imageUrl} alt={item.desc} />
                  <span>{item.desc}</span>
                </div>
              )
            })}
          </div>
          <div className="composite1-end-box" style={{ backgroundImage: `url(${composite8})`, backgroundPosition: `${-130}px ${420}px`, backgroundSize: '62%', backgroundRepeat: 'no-repeat' }}>
            <div className="composite1-end">
              <div className="composite1-end-imgBox">
                <img src={composite5} alt="区位介绍" />
                <span>location</span>
              </div>
              <div className="composite1-end-desc">
                <span className="composite1-end-desc-title">区位介绍</span>
                <span className="composite1-end-desc-content">
                  中免日上综合体位于浦东机场综合保税区内，面积约25万平米，紧靠浦东国际机场，比邻正在建设中的铁路上海东站，是国内首家在综保区内融合高端进口商品展示销售、活动展演、时尚走秀、仓储物流、办公服务、休闲体验等功能，集数字化、会员制、示范性为一体的综合性智能商业平台。
                </span>
              </div>
            </div>
            <div className="composite1-end" >
              <div className="composite1-end-desc" style={{ paddingLeft: 0, paddingRight: '50px' }}>
                <span className="composite1-end-desc-title">综合设施</span>
                <span className="composite1-end-desc-content">综合体中庭3500平米，17米挑高，是可容纳千名观众的沉浸式秀场，集合了海珀联开合屋盖、锐丰灯光音响效果、240平电子升降舞台、奥运同款1200平可移动大型冰屏等各种高端设备。</span>
              </div>
              <div className="composite1-end-imgBox" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <img src={composite6} alt="区位介绍" />
                <span>Venue</span>
              </div>
            </div>
            <div className="composite1-end">
              <div className="composite1-end-imgBox">
                <img src={composite7} alt="区位介绍" />
                <span>ART</span>
              </div>
              <div className="composite1-end-desc">
                <span className="composite1-end-desc-title">中庭秀场</span>
                <span className="composite1-end-desc-content">专业设计团队</span>
                <span className="composite1-end-desc-content">提供高水平展播设计</span>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }, [currentSlide])
}

export default CooperationPage
