import type { FC } from 'react'
import { Layout } from '../../components/Layout'
import './index.scss'

interface Props {}

const TermsPage: FC<Props> = (props) => {
  return (
    <Layout>
      <div className="page-container terms-page">
        <header className="text-center">
          <h1 className="title">平台购买须知</h1>
          <p className="subtitle">PLATFORM PURCHASE INSTRUCTIONS</p>
        </header>

        <section className="content-item">
          <h2>关于“中免日上”</h2>
          <section className="section-content">
            <ol>
              <li>“中免日上”（以下称“平台”）是中免日上互联科技有限公司（由中国免税品集团与日上免税行携手创立）运营的品牌零售电商平台。</li>
              <li>平台商品涵盖护肤、彩妆、香水、洋酒、时尚精品等众多品类，积累了大批忠实优质用户。</li>
              <li>平台集合中免集团旗下各大旅游零售实体店商品，足不出户，买遍全球，为广大用户提供更加便捷与优质的服务。</li>
              <li>平台坚守大牌正品、倡导品质消费，所有在售商品接受海关全程严格监管。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>平台提供的商品</h2>
          <section className="section-content">
            <table>
              <tr>
                <td>商品分类 </td>
                <td>购买方式 </td>
                <td>交付方式 </td>
                <td>卖方 </td>
                <td>备注</td>
              </tr>
              <tr>
                <td>完税商品</td>
                <td>国内购</td>
                <td>自提或委托第三方物流送达</td>
                <td>日上免税行/海南智慧零售/中免日上</td>
                <td></td>
              </tr>
              <tr>
                <td>免税商品</td>
                <td>免税预定</td>
                <td>线下门店自提</td>
                <td>日上免税行/海南智慧零售</td>
                <td>占个人免税额度</td>
              </tr>
              <tr>
                <td>境电商进口商品</td>
                <td>跨境电商 </td>
                <td>第三方物流送达</td>
                <td>日上免税行（中国）</td>
                <td>占个人年度限额</td>
              </tr>
            </table>
          </section>
        </section>

        <section className="content-item">
          <h2>购买须知</h2>
          <section className="section-content">
            <ol>
              <li>平台所有商品均为正品，请放心选购。</li>
              <li>应国家政策要求，部分商品提交订单前需提供个人身份信息（包括但不限于身份证件信息、航班信息、通讯信息等）。</li>
              <li>您在平台购买的进口商品系依据原产地或原销售地有关质量、安全、卫生、环保、标识等标准或技术规范要求生产和销售，可能与我国标准存在差异，您应在购买前自行辨别。</li>
              <li>您在平台购买的进口商品仅限个人自用，不得进行再次销售。</li>
              <li> 平台会不定期推出折扣活动，但无保价和退差价服务。</li>
              <li> 平台保留调整商品价格的权利 </li>
              <li>如您需要开具发票，请于收货后扫描小票上的二维码，即可开具发票，开票有效期为签收后的一年内。</li>
              <li>因多库存共享，商详页预订库存仅供参考，请以最终下单系统提示库存为准。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>不当购买行为</h2>
          <section className="section-content">
            <ol>
              <li>若您有以下任一情形，将被认定为存在不当购买行为：</li>
              <ul>
                <li>(1) 非出于个人消费使用的目的购买商品；</li>
                <li>(2) 购买商品后无正当理由恶意申请退款，或超出正常交易习惯，拒收商品、拒绝接受服务或频繁提出退换货等售后要求的；</li>
                <li>(3) 采取欺诈手段退换货的，如售后退货掉包、提供虚假凭证等；</li>
                <li>(4) 对于难以避免的商品价格标示错误等情况，您购买此类商品的件数≥4件；</li>
                <li>
                  (5)
                  同一用户重复参与促销活动，“同一用户”指使用同一账号、同一手机号、同一设备、同一身份证、同一支付账号、或同一收货地址等的用户，其中任意一项或数项存在相同、相似、非真实有效、通过特定标记形成关联，或平台有合理理由认为存在关联的，都视为同一用户。
                </li>
              </ul>
              <li>以上不当购买行为一经发现，平台有权采取相应措施，包括但不限于暂停发货、取消订单、拦截已发货的订单，情节严重的还可限制账户部分或全部权限、封禁账号</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>商品运费规则</h2>
          <section className="section-content">
            当您在订单中提供或确认送货地址的，即表示您已授权平台通过第三方快递公司为您送达订单商品，相应的商品运费规则如下：
            <ol>
              <li>
                含有“日上优选官方商城”和（或）“中免日上自营”商品的订单：
                <section>
                  (1) 如订单实付金额满200元，该单运费由平台承担；如订单金额不满200元，您需承担8元运费，超过8元的运费由平台承担。
                  <br />
                   (2) 以上所述的订单实付金额为减去优惠金额（福利金、福利点、积分、礼品卡也包含在内）后您支付的金额。 
                  <br /> (3) 如因您取消部分订单，导致订单实付金额不满200元时，产生的邮费需由您承担，商品退款金额中将扣除8元邮费。
                  <br />
                   (4) 如因平台原因缺货，导致订单实付金额不满200元时，产生对应的邮费由平台承担。  <br />
                  (5) 您在下单时对订单中的平台运费进行确认，下单后，在订单详情中可查看对应订单的实付金额以及平台运费金额。
                </section>
              </li>
              <li>不含“日上优选官方商城”和“中免日上自营”商品的订单（包括但不限于发货地为北京、海南的商品订单），运费由平台承担。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>取消订单</h2>
          <section className="section-content">
            <ol>
              <li>订单支付前，订单内若有取消订单按钮，点击后系统将尝试为您取消订单。</li>
              <li>订单支付成功后，订单内若有退款按钮，点击后系统将尝试为您退款。若订单退款成功，退款15个工作日内原路退还。</li>
              <li>涉及促销活动订单的取消详见具体活动规则。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>关于退换货规则</h2>
          <section className="section-content">
            <ol>
              <li> 商品详情页标注“支持七天无理由退货”的，您可申请无理由退货。无论商品是否适用七天无理由退货规定，如您发现商品存在瑕疵（包括但不限于商品的数量、品类、质量不符合订单要求或相关标准的），您均可申请退换货。</li>
              <li>退换货应在七天期间申请。七天期间自商品送达您次日零时开始计算。商品送达时间以物流签收时间为准。</li>
              <li>
                申请无理由退货时，商品应保持以下完好状态：
                <section>
                  (1) 商品本身、配件及附带的商标吊牌、使用说明书等齐全；
                  <br />
                   (2) 商品能够保持原有品质、功能，无受损、受污、刮开防伪、产生激活（授权）等情形，无难以恢复
                  <br />
                  (3) 原状的外观类使用痕迹、不合理的个人数据使用痕迹；
                  <br />
                   (4) 商品外包装（即指生产厂商的最小销售单元原包装）齐全、无严重破损、受污；
                  <br />
                   (5) 食品（含保健食品）、化妆品、个人护理用品、医疗器械等商品的一次性密封包装未被拆封，且商品特制小标签未损坏。
                </section>
              </li>
              <li>因商品有瑕疵而申请退换货时，需提供商品本身、配件，且附带的商标吊牌、使用说明书等应齐全。商品是否符合退换货条件以消费者权益保护法、国家三包政策、厂商授权的退换货标准为最终判断依据。</li>
              <li>
                申请退换货请通过“联系客服”按钮联系平台售后服务人员。
                <section>
                  (1) 如您申请无理由退货的，请提供商品完好状态的照片，经平台初步判断符合申请条件的，您应自费按要求将完好商品以安全可靠的方式运送至卖方指定的地址。
                  <br />
                   (2) 如您申请瑕疵商品退换货的，请提供相应商品存在瑕疵的具体情况以及证明材料。经平台初步判断符合申请条件的，您可以将商品委托平台指定的第三方物流运送至平台指定的地址，运费由平台承担。
                </section>
                 
              </li>
              <li>
                退换货安排：
                <section>
                   (1) 对于您申请无理由退货的商品，卖方收到商品并对完好状态审核无误后按您原付款路径退还商品款项。与退货商品相关的赠品（如有）价值和商品的原运费将从退款金额中扣除。
                  <br />
                   (2) 对于您申请瑕疵商品退换货的，卖方收到商品并对瑕疵商品情况审核无误后按您原付款路径退还商品款项或者向您提供替换商品。
                </section>
              </li>
              <li>卖方收到商品经审核发现商品无法满足无理由退货或者瑕疵商品退换货的条件，则向您说明理由后将商品返还您，相关鉴定、运输等费用由您承担。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>关于临期及质保期标注</h2>
          <section className="section-content">
            <ol>
              <li>
                平台出售的以下商品属于临期商品：
                <section>
                   (1) 香化类商品（包括香水、护肤品、化妆品等）的剩余有效期在6个月以下的 
                  <br />
                  (2) 酒类商品剩余有效期在2个月以下的 
                  <br />
                  (3) 食品商品剩余有效期在3个月以下的
                </section>
              </li>
              <li> 平台售卖上述临期商品时会加以标注，请您谨慎购买，平台不支持以商品临期为理由的退货申请。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>关于商品版本和商品包装</h2>
          <section className="section-content">
            <ol>
              <li> 因商品产地或批次不同，商品外包装、质地、气味等有所不同属于正常现象，不同版本是根据各国法规和针对当地消费者做出的调整，请放心使用。</li>
              <li> 因品牌方更新包装或升级产品原因，商品会存在新老包装交替发货情况，请以实际收到商品为准，商品图片和详情页面仅供参考。</li>
              <li> 所有商品均为原厂包装出库，部分国际品牌倡导环保理念，有可能原包装即为简易包装，且并无精美礼盒包装或精美手提袋。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>关于发货及物流</h2>
          <section className="section-content">
            <ol>
              <li> 发货时间为您提交订单并支付货款后的1-4天内，如遇大规模促销活动，发货时间可能会顺延1-5天，请您耐心等待。如遇特殊情况（自然灾害或疫情防控）造成的交通管制，您的快递包裹可能延期配送。</li>
              <li>
                因货品属于邮寄送达，包装美观问题不在售后范围内，如您收到商品时遇到缺件、漏液、破损等情况请您签收后7天内反馈在线客服处理，处理期间请配合客服提供所需的有效凭证（有效凭证包含：快递箱6个面的照片及快递面单的照片、商品破损照片、订单截图）。破损商品请勿丢弃和使用。
              </li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>关于订单内显示缺货</h2>
          <section className="section-content">
            <ol>
              <li>发货前我们会再次检查商品质量，在部分商品存在缺陷又无其他存货可替换的情况下，为了保证品质，我们将对该部分商品按缺货选项处理。</li>
              <li>缺货商品，平台将予退款，不予补发。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>关于退款</h2>
          <section className="section-content">
            <ol>
              <li>若已提交成功的订单内商品缺货，订单内缺货商品将显示缺货提醒，您可直接申请退款或联系客服沟通处理。审核通过后缺货商品对应的货款将于该订单发货后的3-20个工作日内由系统自动退回。</li>
              <li> 如出现订单整单缺货，该订单将显示缺货提醒，您可申请退款或联系客服沟通处理，审核通过后该订单对应的货款将于3-20个工作日内由系统自动退回。</li>
              <li>如出现重复支付，重复支付的货款将于下单成功后的3-20个工作日内由系统自动退回。</li>
              <li>因物流原因导致您未收到商品，您可联系客服解决，若申请退款，退款将于问题核实确认后的1-2个月内完成。</li>
              <li>系统完成退款操作后，所有的退款都将通过您支付时的通道原路返还，请注意查收。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>精品百货类商品特别提示</h2>
          <section className="section-content">
            <ol>
              <li>精品百货类商品（包括眼镜、首饰腕表、箱包、鞋履服饰等）发货清关过程中长途跋涉，若有细小划痕、胶水痕迹、线头、轻微气味等不影响商品质量、性能、用途的瑕疵，不属于商品质量问题。</li>
              <li>新品可能会存在来自于皮革、合成革、橡胶、织物、发泡材料、胶水等鞋用材料的一些特定气味，不影响商品使用，不属于商品质量问题。</li>
              <li>精品百货类商品的参数、图片仅供参考，服装类商品尺寸1-3cm误差属正常范围。平台不接受以尺寸不符为理由的退货；因个人情况不同，建议下单前自行确认清楚个人尺码信息，平台推荐尺码仅供参考。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>组合商品</h2>
          <section className="section-content">
            <ol>
              <li>组合商品是多个商品的销售组合，下单后在订单页可查看组合商品内子商品。</li>
              <li>下单后仓库备货前，您可选择取消订单，取消订单规则为整单取消，平台暂不支持单独取消订单中任一商品的要求。</li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>免税预订须知</h2>
          <section className="section-content">
            <ol>
              <li>
                <strong>下单时间</strong>
                <p>最早可提前14天下单，最晚需提前8天下单，即下单日期至提货日期为8至14天，此时间内可随意下单。</p>
              </li>
              <li>
                <strong>提货流程</strong>
                <p>免税预订商品仅可在机场指定店铺提货。切记如果选择出境航班提货，则必须全部提货带离出境。</p>
              </li>
              <li>
                <strong>订单修改</strong>
                <p>如需修改提货日期，必须在提货日期前4个工作日提出申请，且最多延迟3天提货。如遇航班更改及取消，请及时和客服取得联系，如果改签后提货航站楼不变的情况下，基本无碍提货。</p>
              </li>
              <li>
                <strong>退货政策</strong>
                <section>
                  (1) 提货前 
                  <br />• 支持退货。根据海关规定，只能整单提货或整单退货退款，不允许单独商品退货; <br />
                   • 由于您自身原因未提货导致的退款，我们将收取退货手续费，非因您自身原因导致的退货退款，比如航班取消，不收取任何手续费； 
                  <br />
                  • 退款时间为退货后的5个工作日; 
                  <br />
                  (2) 提货后 
                  <br />• 按照提货点现场的海关规定公示执行。
                </section>
              </li>
              <li>
                <strong>购买商品限制</strong>
                <p> 平台购买可以多次下单，出境提货无金额限制，单件商品限购8件，请留意您目的地国家的海关政策。入境提货每人限购8000元（折后），单件商品限购8件。</p>
              </li>
              <li>
                <strong>付款后配货问题</strong>
                <p> 付款成功后，如在配货过程中产生缺货，会在您提货日期前3至4天通知您并退还无货商品的货款至原支付账户，其余货品正常提货即可；如在现场提货时被告知缺货，请您务必保留收据，并在工作时间内及时联系客服处理。</p>
              </li>
              <li>
                <strong>发票问题</strong>
                <p> 如需发票，请在提货时及时和免税店店员说明，免税店将根据提货金额开具发票。</p>
              </li>
              <li>
                <strong>烟草类预订政策</strong>
                <p>烟草类商品根据法律规定仅限店铺现场购买。</p>
              </li>
            </ol>
          </section>
        </section>

        <section className="content-item">
          <h2>跨境电商购买须知</h2>
          <section className="section-content">
            <ol>
              <li>
                <strong>跨境电商年度个人额度</strong>
                <section>
                  (1)您通过平台的跨境电商专栏购买商品会占用您的跨境电商年度个人额度（国家对个人年度交易限制为人民币26000元），自每年1月1日00：00开始计算，请您知晓。您可以访问海关官方网站查询年度个人额度使用情况http://lceb2pub.chinaport.gov.cn。
                  <br />
                   (2) 您购买跨境电商的商品每单订购限额为人民币5000元，同款单品限购8个。
                  <br />
                   (3) 您在本平台购买商品需要遵守海关，电商法等相关法律法规的规定。
                </section>
              </li>
              <li>
                <strong>下单须知</strong>
                <section>
                  (1) 购买跨境电商商品的用户需要实行实名身份信息验证，所有用户的下单人信息、支付信息（银行卡）及收件人信息必须保持一致，用户必须对个人信息准确性、真实性负责。
                  <br />
                   (2) 如因上述下单人信息、支付信息、收件人信息不一致导致系统退单、无法发货等后果，平台享有免责权并无须给予任何补偿。
                </section>
              </li>
              <li>
                <strong>取消订单和退货</strong>
                <section>
                  (1) 由于订单支付成功后，订单数据会立刻传输到海关进行清关出仓，因此订单无法取消。
                  <br />
                   (2) 商品详情页将明确标注该商品是否适用七天无理由退换货。请您下单前仔细阅读商品详情页信息并予以确认后再行购买。
                  <br />
                   (3) 若商品支持7天无理由退货服务的，您可在收到商品7日内，确保退回商品未拆封且符合商品完好状态的情况下，平台收到您退回商品后会在7-15个工作日进行退款。
                  <br />
                   (4) 若商品不支持七天无理由退货服务的，如无质量问题平台不提供退货及换货服务。
                </section>
              </li>
              <li>
                <strong> 开票</strong>
                <p>您购买跨境电商商品等同于在境外直接购买商品，海外商家无法开具国内发票，因此平台不支持提供发票。</p>
              </li>
              <li>
                <strong>关于中文标签</strong>
                <p>您在本平台购买的跨境商品等同于境外购买，故商品本身无中文简体标签及说明书，您可通过商品详情页查看相关产品说明。</p>
              </li>
            </ol>
          </section>
        </section>
      </div>
    </Layout>
  )
}

export default TermsPage
