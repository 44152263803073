import type { FC } from 'react'
import './index.scss'
import Search from '../../components/Search'
import banner from '../../assets/images/contactUs/banner.png'
import { Layout } from '../../components/Layout'
interface Props {}

const BrandOnboardingPage: FC<Props> = (props) => {
  const list = [
    {
      title: '企业会员合作',
      desc: 'corporate@cdfsunrise.com',
    },
    {
      title: '在线客服',
      desc: 'APP/小程序在线客服（9:00-23:00）',
    },
    {
      title: '品牌入驻',
      desc: 'procurement@cdfsunrise.com',
    },
    {
      title: '电话客服',
      desc: '4006130133（10:00-22:00）',
    },
    {
      title: '人才招聘',
      desc: 'hr@cdfsunrise.com',
    },
    {
      title: '联系地址',
      desc: '上海市浦东新区迎宾花苑（朝晖路）主楼',
    },
  ]
  return (
    <Layout>
      <section className="page-container brand-onboarding-page">
        <section className="banner">
          <img src={banner} />
        </section>
        <section className="brand-step-wrapper">
          <section className="title-wrapper">
            <h1 className="title">联系我们</h1>
            <h4 className="sub-title">CONTACT US</h4>
          </section>

          <section className="contact-list">
            {list?.length &&
              list.map((item) => {
                return (
                  <article className="contact-content">
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                  </article>
                )
              })}
          </section>
          <section className="search-wrap text-center">
            <h2>即刻开启 非凡购物之旅</h2>
            <Search style={{ margin: '0 auto' }} text="应用商店/微信小程序/支付宝小程序搜索" boldText="中免日上" />
          </section>
        </section>
      </section>
    </Layout>
  )
}

export default BrandOnboardingPage
