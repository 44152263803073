import type { BrowserRouterProps, HashRouterProps } from 'react-router-dom'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import cfg from '../config'

export const RouterMode = (props?: BrowserRouterProps | HashRouterProps) => {
  if (cfg.mode === 'browser') {
    return <BrowserRouter basename={`/`}>{props?.children}</BrowserRouter>
  }
  return <HashRouter basename="/">{props?.children}</HashRouter>
}
