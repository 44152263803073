import classNames from 'classnames'
import type { FC } from 'react'
import './index.scss'

interface Props {
  title: string
  subTitle: string
  className?: string
}

const SideTitle: FC<Props> = (props) => {
  const { title, subTitle, className } = props
  const cls = classNames('c-side-title', className)
  return (
    <div className={cls}>
      <span className="c-side-title__title">{title}</span>
      <span className="c-side-title__subtitle">{subTitle}</span>
    </div>
  )
}

export default SideTitle
