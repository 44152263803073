import ReactDOM from 'react-dom'
import './assets/styles/entry.css'
import { routeMapping } from './routes/index'
import { RouterMode } from './routes/renderMode'
import RenderRoutes from './routes/renderRoutes'
ReactDOM.render(
  <>
    {/*@ts-ignore*/}
    <RouterMode>
      <RenderRoutes routes={routeMapping} />
    </RouterMode>
  </>,
  document.getElementById('root')
)
